import aliceJpg from 'img/shows/alice.jpg';
import aliceWebp from 'img/shows/alice.webp';
import sleepingJpg from 'img/shows/sleeping-adult.jpg';
import sleepingWebp from 'img/shows/sleeping-adult.webp';
import beastJpg from 'img/shows/beast.jpg';
import beastWebp from 'img/shows/beast.webp';
import jackAltonJpg from 'img/shows/jack.jpg';
import jackAltonWebp from 'img/shows/jack.webp';
import jackBordonJpg from 'img/shows/jack.jpg';
import jackBordonWebp from 'img/shows/jack.webp';
import rapunzelJpg from 'img/shows/rapunzel.jpg';
import rapunzelWebp from 'img/shows/rapunzel.webp';

const isWebpSupported = () => true;

interface Venue {
  key: string;
  location: string;
  hoverText?: string;
  link: string;
  poster?: string;
};
interface Event {
  title: string;
  venues: Venue[];
  description: string;
  poster?: string;
  sub?: string;
};

const links = {
  rapunzel: {
    basingstoke: 'https://www.ticketsource.co.uk/pumpkinpantos/rapunzel-easter-panto-basingstoke/e-vkgjyq',
    alton: 'https://www.ticketsource.co.uk/pumpkinpantos/rapunzel-easter-panto-alton/e-xjmvbb'
  },
  // sleeping: {
  //   basingstoke: 'https://www.ticketsource.co.uk/pumpkinpantos/sleeping-with-beauty-basingstoke/e-obbkjq',
  //   alton: 'https://www.ticketsource.co.uk/pumpkinpantos/sleeping-with-beauty-alton/e-vlljpx'
  // },
  // alice: {
  //   basingstoke: 'https://www.ticketsource.co.uk/pumpkinpantos/alice-in-wonderland-basingstoke/e-gbqydr',
  //   alton: 'https://www.ticketsource.co.uk/pumpkinpantos/alice-in-wonderland-alton/e-xlbzlq'
  // },
  // jack: {
  //   bordon: 'https://www.ticketsource.co.uk/pumpkinpantos/e-ayroeq',
  //   alton: 'https://www.ticketsource.co.uk/pumpkinpantos/e-zrkvre'
  // },
  // beast: {
  //   bordon: 'https://www.ticketsource.co.uk/PUMPKINPANTOS/beauty-and-the-beast-bordon/e-rkzqbo',
  //   alton: 'https://www.ticketsource.co.uk/pumpkinpantos/beauty-and-the-beast-alton/e-mympqr'
  // }
}

export const events: Event[] = [{
  title: "2025 Easter Panto",
  poster: isWebpSupported() ? rapunzelWebp : rapunzelJpg,
  description: `Follow the enchanting story of Rapunzel, the princess with magical golden hair.
Upon learning of the princess's luscious locks, an evil sorceress named Gothel captures Rapunzel in the hope of gaining eternal beauty.
Can Fairy Fabulous, Strider and Dolly Dumpling help to free Rapunzel from the confines of Gothel's tower?
This hair-larious pantomime features sensational songs, colourful costumes and a laugh-a-minute script.
So let your hair down and get ready to enjoy Pumpkin Pantos' Easter spectacular!`,
  venues: [{
    key: "basingstoke",
    location: "Basingstoke",
    // hoverText: "On Sale Soon!",
    link: links.rapunzel.basingstoke
  },{
    key: "alton",
    location: "Alton",
    // hoverText: "On Sale Soon!",
    link: links.rapunzel.alton
  }]
}];

interface PastEvent {
  title: string,
  venues: Omit<Venue, "link">[]
  description: string;
  poster?: string;
}
const pastEvents: PastEvent[] = [{
  title: "Adult's only Panto",
  poster: isWebpSupported() ? sleepingWebp : sleepingJpg,
  venues: [{
    key: "basingstoke",
    location: "Basingstoke",
    // link: links.sleeping.basingstoke
  },{
    key: "alton",
    location: "Alton",
    // link: links.sleeping.alton,
  }],
  // sub: `ADULTS ONLY! 18+`,
  description: `Prepare to be titillated and tantalised with our fabulously filthy fairytale that will leave you blushing and begging for more! 
Meet a prince on the pull, a fairy with a flaccid wand and a sorceress hell-bent on giving the princess a little prick.  Plus there's plenty of mischief from Nurse Noo-Noo and Fiddles the Jester.
The world of pantomime will be spun on its head in this hilarious, innuendo-filled show packed with sauce, smut and shocks. 
It's cheeky! It's naughty! It's raunchy! It's everything a panto shouldn't be!`
  },{
  title: "Summer Panto",
  venues: [{
    key: "basingstoke",
    location: "Basingstoke",
  },{
    key: "camberley",
    location: "Camberley",
  },{
    key: "bordon",
    location: "Bordon",
  }],
    poster: '',//<TreasurePoster></TreasurePoster>,
    description: `Climb aboard the good ship Hispaniola and join Jimmy Hawkins on a daring quest full of pillaging pirates, deserted islands and magical mermaids. Throw in an enchanted treasure map and a perky parrot and you’ve got yourself the most exciting adventure of all time.</p>
Jimmy must set sail across the high seas to seek a chest full of riches beyond his wildest dreams. Can our hero claim the treasure in time or will the dastardly pirates beat him to it?</p>
This swashbuckling pantomime promises side-splitting comedy, sensational songs and audience participation in abundance!</p>
An epic journey awaits you!`
},{
  title: "2022 Christmas Panto",
  venues: [{
    key: "alton",
    location: "Alton",
    hoverText: "On Sale Soon!",
    // link: links.jack.alton,
    poster: isWebpSupported() ? jackAltonWebp : jackAltonJpg
  },{
    key: "bordon",
    location: "Bordon",
    hoverText: "On Sale Soon!",
    // link: links.jack.bordon,
    poster: isWebpSupported() ? jackBordonWebp : jackBordonJpg
  }],
  description: `Climb up the beanstalk with Jack on a GIGANTIC adventure to find fame, fortune and true love!
Add a lovable cow, a dastardly giant (and throw in some magic beans for good measure) and you have the perfect ingredients for a truly magnificent pantomime.
Expect colourful costumes, bucket loads of comedy and toe-tapping musical numbers.
It’s going to be Fe-Fi-Fo-FUN!`
},{
  title: "2023 Christmas Panto",
  poster: isWebpSupported() ? beastWebp : beastJpg,
  venues: [{
    key: "alton",
    location: "Alton",
    // link: links.beast.alton
  },{
    key: "bordon",
    location: "Bordon",
    // link: links.beast.bordon
  }],
  description: `Pumpkin Pantos are back with another spellbinding pantomime!
Join us for a tale as old as time; featuring a cursed prince, an enchanted rose and a magical castle.
Can the cold-hearted Beast find true love before it's too late? Is the beautiful Belle his chance of a happy ever after? Will the funny fella in a frock return? Only time will tell...
This promises to be a spectacular production with stunning scenery, lavish costumes, memorable songs and laughs galore.`
},{
  title: "Summer Panto",
  poster: isWebpSupported() ? aliceWebp : aliceJpg,
  venues: [{
    key: "basingstoke",
    location: "Basingstoke",
    // link: links.alice.basingstoke
  },{
    key: "alton",
    location: "Alton",
    // link: links.alice.alton,
  }],
  description: `Follow Alice down the rabbit hole to the magical world of Wonderland. Have tea with the Mad Hatter, smile with the Cheshire Cat, laugh with the Tweedles but beware of the horrid Queen of Hearts! 
This fast-paced production is jam-packed with colourful costumes, sensational musical numbers and side-splitting comedy. So don't be late; book your tickets today. You'd be mad to miss it!`
}];