/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react';
import styled from 'styled-components';
// import {isWebpSupported} from 'react-image-webp/dist/utils';
import { PageWrapper, PageSection, PageDetails, ButtonA, Flex } from '../components';

import { events } from "data/events";

const Poster = styled.div<{src: string}>`
  background-image: url(${({src}) => src});
  flex: 1;
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.3s ease-out;
  margin: 24px 0;
  width: 50vh;
  max-width: 100%;
  height: 0;
  /*padding-top: calc(1188 / 840 * 100%);*/
  padding-top: calc(707 / 840 * 100%);
  
  @media (min-width: ${props => props.theme.maxWidth /2 + 1}px) {
    &:hover {
      transform: scale(1.05);
    }

    &.disabled:hover {
      transform: none;
    }
  }
  img {
    display: none;
  }
  @media (max-width: ${props => props.theme.maxWidth /2}px) {
    width: calc(100vw - 4em);
  }

  &.disabled {
    cursor: not-allowed;
    /*pointer-events: none;*/
  }
  `;

const StyledPageDetails = styled(PageDetails)<{ $smallPad?: boolean }>`
  columns: 300px 2;
  column-gap: 4em;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  flex-direction: unset;
  align-items: unset;
  display: block;
  text-align: justify;
  & > * {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    font-size: 20px;
  }

  & > *:first-child {
    font-variant: small-caps;
    margin-top:0;
    font-weight: bold;
    font-size: 24px;
  }
  ${props => props.$smallPad && "margin-top: 12px;"}
`;

const H1 = styled.h1`
  @media (max-width: ${props => props.theme.maxWidth /2}px) {
    margin: 0;
  }
  font-size: 28px;
  margin: 0;
`;

const StyledFlex = styled(Flex)`
  max-width: calc(100vw - 4em);
  margin: 2em;
  a.disabled {
    cursor: not-allowed;
    /*pointer-events: none;*/
  }
`;

const SubTitle = styled.h2`
  margin-top: 12px !important;
`;

const StyledFlexFlip = styled(StyledFlex)`
  flex-direction: row;
  margin-top: 0px;
  
  @media (max-width: ${props => props.theme.maxWidth /1.5}px) {
    flex-direction: column;
  }
`;

const ContainerFlex = styled(Flex)`
  margin-top: -2em;
  margin-bottom: -2em;
`

export default function EventsPage() {
  useEffect(() => {
    document.title = "Shows & Events | Pumpkin Pantos";
  }, []);

  return (
    <PageWrapper>
      {events.map(({title, venues, description, poster, sub}) => (
        <PageSection direction="column" alignItems="center" className="mid" key={title}>
          <h1>{title}</h1>
          <ContainerFlex justifyContent="center">
            {venues.map(({key, location, hoverText, link, poster: venuePoster}) => (
              <StyledFlex direction="column" key={key}>
                <a target="_blank" href={link} rel="noreferrer" title={hoverText}><H1>{location}</H1></a>
                <Poster as="a" target="_blank" href={link} rel="noreferrer" title={hoverText} src={venuePoster || poster || ''}></Poster>
                <ButtonA href={link} target="_blank" rel="noopener noreferrer">{`Buy Tickets - ${location}`}</ButtonA>
              </StyledFlex>
            ))}
          </ContainerFlex>
          {sub && <SubTitle>{sub}</SubTitle>}
          <StyledPageDetails $smallPad={!!sub}>
            {description.split('\n').map((text: string, index) => <p key={index}>{text}</p>)}
          </StyledPageDetails>
        </PageSection>
      ))}
      {/* <PageSection>
        <PageDetails>
          <h1>Stay in the Know...</h1>
          <p>We are taking a break from producing shows during this Christmas 2024 season but we look forward to putting on more fun and frivolity in 2025!</p>
          <p>Check back soon for our latest upcoming productions and events. In the meantime, click here to join our mailing list to be the first to hear about tickets and shows.</p>
          <ButtonA target="_blank" href="https://forms.gle/xLcW2pPuuN8EHLhZ6" rel="noreferrer">Join Our Mailing List</ButtonA>
        </PageDetails>
      </PageSection> */}
    </PageWrapper>
  );
}